(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name combo.factory:Combo
   *
   * @description
   *
   */
  angular
    .module('combo')
    .factory('Combo', Combo);

  function Combo() {
    var ComboBase = {};
    ComboBase.someValue = 'Combo';
    ComboBase.someMethod = function () {
      return 'Combo';
    };
    return ComboBase;
  }
}());
